.navbar-container {
  background-color: rgb(235, 243, 255);
}
.navbar {
  padding-top: 0px;
  padding-bottom: 0px;
  padding-left: 70px;
  padding-right: 130px;
}
.container-fluid {
  /* line-height: 1; */
  margin: 15px 30px;
}
.nav-item {
  margin: 5px 7px;
}
.navbar-brand {
  padding: 12px 20px;
  border-radius: 30px;
  margin-right: 50px;
  box-shadow: rgb(255 255 255) -20px -20px 40px,
    rgb(0 102 255 / 30%) 10px 10px 20px;
  color: red;
  font-weight: bold;
}
.nav-li-info {
  font-size: 18px;
  font-weight: bold;
  color: rgb(0, 41, 79);
}
.mail-info-container {
  background-color: rgba(0, 41, 79, 0.5);
  padding: 5px 12px;
  border-radius: 30px;
  /* display: flex; */
  align-items: center;
  color: white;
  font-weight: bold;
  font-size: 16px;
}

.main-container {
  /* font-size: 17px; */
  padding: 8px 16px;
  background-color: rgb(0, 93, 180);
  color: rgb(255, 255, 255);
  border-radius: 30px;
  text-transform: capitalize;
  /* font-weight: bold; */
  margin-right: 20px;
}
.contact-info {
  color: rgb(255, 255, 255);
}
@media (max-width: 500px) {
  .navbar-brand {
    margin-right: 0px;
  }
  .navbar-nav {
    flex-direction: row;
  }
  .navbar {
    width: 100vw;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-left: 0px;
    padding-right: 0px;
  }
  .navbar > .container-fluid {
    margin: 15px 0px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
