.our-services-head-container {
  font-family: lato, sans-serif;
  font-size: 48px;
  color: rgb(30, 53, 71);
}

.our-service-underline {
  width: 100px;
  height: 5px;
  background-color: rgb(117, 142, 167);
}
.our-service-we-provide-quality-service-containter {
  /* text-align: center; */
  font-size: 24px;
  font-weight: 100;
  margin-bottom: 100px;
}
.our-service-web-application-parent-container {
  text-align: center;
  /* padding: 20px; */
  padding-top: 80px;
  padding-bottom: 20px;
  padding-left: 20px;
  padding-right: 20px;
  border: 1px solid rgb(241, 241, 241);
  width: 300px;
  border-radius: 10px;
  box-shadow: 0px 5px 20px rgb(133 155 187 / 30%),
    5px -3px 20px rgb(138 141 147 / 15%);
  margin-right: 50px;
  margin-bottom: 100px;
  position: relative;
}

.our-service-circle-desktop-container {
  background-color: rgb(230, 226, 226);
  height: 100px;
  width: 100px;
  border: 5px solid rgb(255 255 255);
  /* transform: translateY(-70%); */
  box-shadow: 0px 1px 7px rgb(133 131 133 / 30%);
  position: absolute;
  top: -20%;
}
.desktop-img {
  height: 50px;
  width: 50px;
}
.our-service-web-application {
  font-size: 22px;
  font-weight: 500;
  margin-bottom: 20px;
  /* margin-top: -28px; */
}
.our-service-e-commerce-container {
  font-size: 14px;
  line-height: 2;
}
.our-service-mobile-tab-application {
  font-size: 22px;
  font-weight: 500;
  margin-bottom: 20px;
  margin-top: -50px;
}
.our-service-project-consultancy-application {
  font-size: 22px;
  font-weight: 500;
  margin-bottom: 20px;
  margin-top: -60px;
}
.our-service-business-intelligence {
  font-size: 22px;
  font-weight: 500;
  margin-bottom: 20px;
  margin-top: -40px;
}
.our-service-cloud-application {
  font-size: 22px;
  font-weight: 500;
  margin-bottom: 20px;
  margin-top: 0px;
}
.our-service-maintenance-support {
  font-size: 22px;
  font-weight: 500;
  margin-bottom: 20px;
  margin-top: -20px;
}
@media (max-width: 500px) {
  .our-services-head-container {
    font-size: 34px;
  }
  .our-service-we-provide-quality-service-containter {
    text-align: center;
    font-size: 20px;
    font-weight: lighter;
  }
  .our-service-web-application-parent-container {
    margin-right: 0px;
  }
  .our-service-web-application-parent-container {
    font-size: 45px;
    /* text-align: center; */
  }
}
