.technology-expertise-computing-parent-container {
  text-align: center;
  padding: 20px;
  padding-top: 80px;
  background-color: white;
  border: 1px solid rgb(241, 241, 241);
  width: 300px;
  border-radius: 10px;
  box-shadow: 0px 5px 20px rgb(133 155 187 / 30%),
    5px -3px 20px rgb(138 141 147 / 15%);
  margin-right: 50px;
  margin-bottom: 100px;
}
.technology-expertise-text-container {
  font-family: lato, sans-serif;
  font-size: 48px;
  color: rgb(30, 53, 71);
}
.technology-expertise-img-container {
  background-color: rgb(230, 226, 226);
  height: 100px;
  width: 100px;
  border: 5px solid rgb(255 255 255);
  top: 0%;
  transform: translateY(-50%);
  box-shadow: 0px 1px 7px rgb(133 131 133 / 30%);
}

.technology-expertise-computing {
  font-size: 22px;
  font-weight: 500;
  /* margin-bottom: 20px; */
  /* margin-top: -28px; */
}
.technology-expertise-Python-nodejs-container {
  font-size: 14px;
  line-height: 2;
}
@media (max-width: 500px) {
  .technology-expertise-computing-parent-container {
    margin-right: 0px;
  }
  .technology-expertise-text-container {
    text-align: center;
  }
}
